import JSZip from "jszip";

export const readWordFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(event) {
      JSZip.loadAsync(event.target.result).then(zip => {
        return zip.file("word/document.xml").async("string");
      })
      .then(content => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, "text/xml");
        let text = "";
        const paragraphs = xmlDoc.getElementsByTagName("w:p");
        for (let i = 0; i < paragraphs.length; i++) {
          const textElements = paragraphs[i].getElementsByTagName("w:t");
          for (let j = 0; j < textElements.length; j++) {
            text += textElements[j].textContent;
          }
          text += "\n"; // Добавляем перенос строки после каждого абзаца
        }
        resolve(text);
      })
      .catch(err => {
        reject(err);
      });
    };
    reader.readAsArrayBuffer(file);
  });
};
