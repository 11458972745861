import { useState, useEffect } from 'react';

function useClearableInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  const [showClearButton, setShowClearButton] = useState(value.length > 0);

  useEffect(() => {
    setShowClearButton(value.length > 0);
  }, [value]);

  const ClearButton = () => {
    if (!showClearButton) return null;
    return (
      <span 
        onClick={() => setValue('')}
        style={{
          position: 'absolute',
          top: '50px',
          right: '20px',
          cursor: 'pointer',
          transform: 'translateY(-50%)',
          fontSize: '1.2rem',
          color: '#aaa'
        }}
      >
        ✖
      </span>
    );
  };

  return [value, setValue, ClearButton];
}

export default useClearableInput;
