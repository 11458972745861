import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SelectTemplate({ setBasePrompt, setSelectedTemplate }) {
  const { t, i18n } = useTranslation();
  const [promptTemplates, setPromptTemplates] = useState([]);

  // Загрузка шаблонов промптов в зависимости от выбранного языка
  useEffect(() => {
    async function loadTemplates() {
      try {
        const templates = await import(`../../locales/${i18n.language}/promptTemplates.json`);
        setPromptTemplates(templates.default);
      } catch (e) {
        console.error("Не удалось загрузить шаблоны промптов: ", e);
      }
    }
    loadTemplates();
  }, [i18n.language]);

  const handleTemplateChange = (e) => {
    const selectedName = e.target.value;
    const selectedPrompt = promptTemplates.find(template => template.name === selectedName);
    if (selectedPrompt) {
      setBasePrompt(selectedPrompt.template);
    }
  };

  return (
    <div className="form-group">
      <label style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>
        {t('choosePromptSample')}
      </label>
      <select
        className="form-control custom-select"
        style={{ boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)', borderRadius: '4px' }}
        onChange={handleTemplateChange}
      >
        <option value="" disabled selected>
          {t('selectPromptTemplate')}
        </option>
        {promptTemplates.map((template, index) => (
          <option key={index} value={template.name}>{template.name}</option>
        ))}
      </select>
    </div>
  );
}

export default SelectTemplate;
