import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();
  const [imageURL, setImageURL] = useState('');

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    const randomImageNumber = getRandomInt(1, 179);
    const paddedNumber = String(randomImageNumber).padStart(5, '0');
    const newImageURL = `/images/${paddedNumber}.jpg`;
    setImageURL(newImageURL);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <h1 style={{ marginTop: '20px' }}>{t('header.title')}</h1>
        <p>{t('header.subtitle')}</p>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <img src={imageURL} alt={t('header.imageAlt')} style={{ height: '180px', marginTop: '5px' }} />
      </div>
    </div>
  );
}

export default Header;
