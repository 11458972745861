import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectTemplate from './promptForm/SelectTemplate';
import PromptInput from './promptForm/PromptInput';
import TextInput from './promptForm/TextInput';
import DelimiterSelection from './promptForm/DelimiterSelection';
import FileReadingLogic from './promptForm/FileReadingLogic';

function PromptForm({
    basePrompt, 
    setBasePrompt, 
    largeText, 
    setLargeText, 
    setDelimiterOption, 
    setDelimiterValue, 
    handleSubmit 
}) {
    
    const { t } = useTranslation();

    const {
        pdfUrl, 
        setPdfUrl, 
        file, 
        setFile, 
        handleFileRead, 
        handlePdfUrlLoad
    } = FileReadingLogic({
        onFileRead: setLargeText, 
        onPdfUrlLoad: setLargeText
    });

    return (
        <form onSubmit={handleSubmit}>
            <SelectTemplate 
                setBasePrompt={setBasePrompt} 
            />

            <PromptInput 
                basePrompt={basePrompt} 
                setBasePrompt={setBasePrompt} 
            />

            <TextInput 
                largeText={largeText} 
                setLargeText={setLargeText} 
                pdfUrl={pdfUrl} 
                setPdfUrl={setPdfUrl} 
                handleFileRead={handleFileRead} 
                handlePdfUrlLoad={handlePdfUrlLoad} 
                fileName={file ? file.name : ''}  
            />

            <DelimiterSelection 
                setDelimiterOption={setDelimiterOption} 
                setDelimiterValue={setDelimiterValue} 
            />

            <button 
                type="submit" 
                className="btn btn-primary" 
                style={{
                    marginTop: '10px', 
                    marginBottom: '10px', 
                    display: 'block', 
                    marginLeft: 'auto', 
                    marginRight: 'auto'
                }}
            >
                {t('generateSetOfPrompts')}
            </button>
        </form>
    );
}

export default PromptForm;
