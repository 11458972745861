import React from 'react';

function Footer() {
  return (
    <footer style={{ textAlign: 'center', padding: '5px' }}>
      <p><a href="mailto:info@promptozaurus.com">info@promptozaurus.com</a></p>
    </footer>
  );
}

export default Footer;
