import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf';

// Функция для чтения PDF файлов с пользовательского устройства
export const readPdfFile = (file) => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(event) {
      const typedArray = new Uint8Array(event.target.result);
      extractTextFromPdf(typedArray, resolve, reject);
    };
    reader.readAsArrayBuffer(file);
  });
};

// Функция для чтения PDF файлов по URL
export const readPdfFromUrl = (url) => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.arrayBuffer();
      })
      .then(buffer => {
        const typedArray = new Uint8Array(buffer);
        extractTextFromPdf(typedArray, resolve, reject);
      })
      .catch(error => {
        reject('Error during PDF download or processing: ', error);
      });
  });
};

// Вспомогательная функция для извлечения текста из PDF
const extractTextFromPdf = (typedArray, resolve, reject) => {
  pdfjsLib.getDocument(typedArray).promise.then((pdf) => {
    let textContent = '';
    let numPages = pdf.numPages;

    const getPageText = (pageNum) => {
      return pdf.getPage(pageNum).then((page) => {
        return page.getTextContent();
      }).then((content) => {
        let lastItem = null;
        content.items.forEach((item, index) => {
          if (lastItem && lastItem.transform[5] !== item.transform[5]) {
            textContent += '\n';
          }
          textContent += item.str + ' ';
          lastItem = item;
        });
        textContent += '\n';
        if (pageNum < numPages) {
          return getPageText(pageNum + 1);
        }
      });
    };

    getPageText(1).then(() => {
      resolve(textContent);
    }).catch((error) => {
      reject(error);
    });
  });
};
