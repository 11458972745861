import React from 'react';
import { useTranslation } from 'react-i18next';
import useClearableInput from '../useClearableInput';

function DelimiterSelection({ setDelimiterOption, setDelimiterValue }) {
  const { t } = useTranslation();
  const [delimiterValue, setDelimiterValueLocal, ClearButton] = useClearableInput('');

  const handleDelimiterChange = (e) => {
    setDelimiterValueLocal(e.target.value);
    setDelimiterValue(e.target.value);
  };

  return (
    <div>
      <div className="form-group">
        <label style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{t('delimiterSelection')}</label>
        <div>
          <div className="form-check">
            <input className="form-check-input" 
                   type="radio" 
                   name="delimiter" 
                   value="volume" 
                   onChange={(e) => setDelimiterOption(e.target.value)} 
            />
            <label className="form-check-label">
              {t('splitBySymbolsCount')}
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" 
                   type="radio" 
                   name="delimiter" 
                   value="symbols" 
                   onChange={(e) => setDelimiterOption(e.target.value)} 
            />
            <label className="form-check-label">
              {t('splitByDelimiters')}
            </label>
          </div>
        </div>
      </div>

      <div className="form-group" style={{ position: 'relative' }}>
        <label style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{t('delimiterValue')}</label>
        <textarea 
          className="form-control" 
          value={delimiterValue}  
          onChange={handleDelimiterChange}  
          required 
          placeholder={t('delimiterValuePlaceholder')}
        />
        <ClearButton />
      </div>
    </div>
  );
}

export default DelimiterSelection;
