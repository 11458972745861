import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { readTextFile } from '../fileReader';
import { readWordFile } from '../readWordFile';
import { readPdfFile, readPdfFromUrl } from '../readPdfFile';

function FileReadingLogic({ onFileRead, onPdfUrlLoad }) {
  const { t } = useTranslation();
  const [pdfUrl, setPdfUrl] = useState(''); 
  const [file, setFile] = useState(null);

  const handleFileRead = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
      
      if (fileType === 'txt') {
        readTextFile(file)
          .then((text) => {
            onFileRead(text);
          })
          .catch((error) => {
            alert(t('fileReadError'));
          });
      } else if (fileType === 'docx') {
        readWordFile(file)
          .then((text) => {
            onFileRead(text);
          })
          .catch((error) => {
            alert(t('fileReadError'));
          });
      } else if (fileType === 'pdf') {
        readPdfFile(file)
          .then((text) => {
            onFileRead(text);
          })
          .catch((error) => {
            alert(t('fileReadError'));
          });
      } else {
        alert(t('unsupportedFileType'));
      }
    }
  };

  const handlePdfUrlLoad = () => { 
    readPdfFromUrl(pdfUrl)
      .then((text) => {
        onPdfUrlLoad(text);
      })
      .catch((error) => {
        alert(t('pdfUrlLoadError'));
      });
  };

  return { pdfUrl, setPdfUrl, file, setFile, handleFileRead, handlePdfUrlLoad };
}

export default FileReadingLogic;
