import React, { useState, useEffect } from 'react';

function PromptList({ generatedPrompts, handleCopyPrompt, copiedPrompts }) {
  // Инициализация состояния для хранения URL выбранных картинок для каждого промпта
  const [imageURLs, setImageURLs] = useState([]);

  // Функция для генерации случайного числа от min до max
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Хук useEffect для выбора случайных картинок при обновлении generatedPrompts
  useEffect(() => {
    const newImageURLs = generatedPrompts.map(() => {
      const randomImageNumber = getRandomInt(1, 179);
      const paddedNumber = String(randomImageNumber).padStart(5, '0');
      return `/images/${paddedNumber}.jpg`;
    });
    setImageURLs(newImageURLs);
  }, [generatedPrompts]);

  return (
    <div className="prompts-container mt-4">
      {generatedPrompts.map((prompt, index) => (
        <div className="form-group mb-4 position-relative" key={index}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={imageURLs[index]} alt="Random animal" style={{ height: '120px', marginRight: '20px' }} />
            <div style={{ flex: 1 }}>  {/* Добавлен стиль flex: 1 */}
              <h5>Prompt #{index + 1}</h5>
              <span className="label-characters-count" style={{position: 'absolute', top: '5px', right: '10px', backgroundColor: 'green', color: 'white', padding: '2px 6px', borderRadius: '5px', fontSize: '0.8rem'}}>{prompt.length}</span> 
              <div className="input-group">
                <textarea 
                  className="form-control" 
                  value={prompt} 
                  rows="3" 
                  readOnly 
                  style={{ backgroundColor: copiedPrompts.includes(index) ? 'lightgray' : 'white' }}
                />
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" type="button" onClick={() => handleCopyPrompt(prompt, index)} style={{ marginLeft: '10px' }}>Copy</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PromptList;
