export const splitTextIntoFragments = (text, delimiterOption, delimiterValue) => {
  let fragments = [];
  if (delimiterOption === "volume") {
    if (isNaN(delimiterValue) || delimiterValue <= 0) {
      throw new Error("Invalid delimiter value. Please enter a positive number.");
    }
    while (text.length) {
      let fragment = text.slice(0, delimiterValue);
      let lastDotIndex = fragment.lastIndexOf('.');
      if (lastDotIndex !== -1) {
        fragment = fragment.slice(0, lastDotIndex + 1);
      }
      if (fragment.trim() !== '') {
        fragments.push(fragment.trim());
      }
      text = text.slice(fragment.length).trim();
    }
    } else if (delimiterOption === "symbols") {
      if (delimiterValue.includes('{%N}')) {
        const regExpPattern = delimiterValue.replace('{%N}', '\\d+');
        const regex = new RegExp(regExpPattern, 'g');
        let match;
        let lastIndex = 0;
        while ((match = regex.exec(text)) !== null) {
          fragments.push(text.slice(lastIndex, match.index).trim());
          lastIndex = regex.lastIndex;
        }
        fragments.push(text.slice(lastIndex).trim());
      } else {
        while (text.length) {
          let splitIndex = text.indexOf(delimiterValue);
          let fragment;
          if (splitIndex !== -1) {
            fragment = text.slice(0, splitIndex + delimiterValue.length);
            text = text.slice(splitIndex + delimiterValue.length).trim();
          } else {
            fragment = text;
            text = "";
          }
          fragments.push(fragment.trim());
        }
      }
    }
    return fragments;
  };
  