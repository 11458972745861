// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/en.json";
import ru from "./locales/ru/ru.json";
import es from "./locales/es/es.json";  // Импорт файла локализации для испанского языка

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  es: {  // Добавление ресурсов для испанского языка
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
