import './i18n';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Footer from './components/Footer';
import PromptForm from './components/PromptForm';
import PromptList from './components/PromptList';
import { splitTextIntoFragments } from './components/textProcessing';
import './App.css';

function App() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en");  // новое состояние

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setCurrentLanguage(lng);  // Обновление текущего языка
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setCurrentLanguage(savedLanguage);  // Установка текущего языка на сохраненный
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(95355956, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
      });
    `;
    document.body.appendChild(script);
  }, []);

  const [basePrompt, setBasePrompt] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [largeText, setLargeText] = useState("");
  const [delimiterOption, setDelimiterOption] = useState("volume");
  const [delimiterValue, setDelimiterValue] = useState("");
  const [generatedPrompts, setGeneratedPrompts] = useState([]);
  const [copiedPrompts, setCopiedPrompts] = useState([]);

  const handleCopyPrompt = (prompt, index) => {
    // Создание временного элемента textarea
    const textarea = document.createElement("textarea");
    textarea.value = prompt;
    document.body.appendChild(textarea);
    
    // Выделение и копирование текста
    textarea.select();
    document.execCommand("copy");
    
    // Удаление временного элемента textarea
    document.body.removeChild(textarea);
    
    // Логика для обозначения того, что промпт был скопирован (если необходимо)
    setCopiedPrompts([...copiedPrompts, index]);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setCopiedPrompts([]);
    
    try {
      let textFragments = splitTextIntoFragments(
        largeText,
        delimiterOption,
        delimiterOption === "volume" ? parseInt(delimiterValue) : delimiterValue.replace('\\n', '\n')
      );
  
      let prompts = textFragments.map((fragment, index) => {
        return basePrompt.replace('<text>', fragment);
      });
  
      setGeneratedPrompts(prompts);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="container">
      <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 1000 }}>
        {/* Обновленный список языков, теперь с испанским языком (es) */}
        <select onChange={(e) => changeLanguage(e.target.value)} value={currentLanguage}>
          <option value="en">En</option>
          <option value="ru">Ru</option>
          <option value="es">Es</option>  {/* Добавлена опция для испанского языка */}
        </select>
      </div>

      <Helmet>
        <title>promptozaurus</title>
        <meta name="description" content="Split large texts into manageable fragments and generate AI and chatbot prompts using customizable templates for each fragment." />
        <meta name="keywords" content="promptozaurus, promptozaurus.com, Prompts, AI Prompts, Prompts for Artificial Intelligence, Chatbot Prompts, Prompts for Chatbots, Prompt Generation, Automated Prompt Creation, Text Segmentation, Text Splitting, AI Text Processing, Text Processing with Artificial Intelligence, Text Fragmentation, Text Chunking" />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="promptozaurus" />
        <meta property="og:description" content="Split large texts and generate prompts for AI chatbots." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bitetheelephant.com/" />
        <meta property="og:image" content="https://www.bitetheelephant.com/logo_elephant512.jpg" />
      </Helmet>

      <Header />

      <PromptForm
        setBasePrompt={setBasePrompt}
        setSelectedTemplate={setSelectedTemplate}
        setLargeText={setLargeText}
        setDelimiterOption={setDelimiterOption}
        setDelimiterValue={setDelimiterValue}
        handleSubmit={handleSubmit}
        basePrompt={basePrompt}
        largeText={largeText}
      />

      <PromptList generatedPrompts={generatedPrompts} copiedPrompts={copiedPrompts} setCopiedPrompts={setCopiedPrompts} handleCopyPrompt={handleCopyPrompt} />

      <Footer />
    </div>
  );
}

export default App;
