import React, { useEffect } from 'react';
import useClearableInput from '../useClearableInput';
import { useTranslation } from 'react-i18next';

function PromptInput({ basePrompt, setBasePrompt }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue, ClearButton] = useClearableInput(basePrompt);

  useEffect(() => {
    setInputValue(basePrompt);
  }, [basePrompt]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setBasePrompt(e.target.value);
  }

  return (
    <div className="form-group position-relative">
      <label style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{t('promptLabel')}</label>
      <span className="label-characters-count" 
            style={{position: 'absolute', 
                    top: '5px', 
                    right: '10px', 
                    backgroundColor: 'green', 
                    color: 'white', 
                    padding: '2px 6px', 
                    borderRadius: '5px', 
                    fontSize: '0.8rem'}}
      >
        {inputValue.length}
      </span>
      <textarea 
        className="form-control"
        value={inputValue}
        onChange={handleInputChange} 
        rows="5" 
        required 
        placeholder={t('basePromptPlaceholder')}
      />
      {ClearButton()}
    </div>
  );
}

export default PromptInput;
