import React, { useState, useEffect } from 'react';
import { readTextFile } from '../fileReader';
import { readWordFile } from '../readWordFile';
import { readPdfFile, readPdfFromUrl } from '../readPdfFile';
import useClearableInput from '../useClearableInput';
import { useTranslation } from 'react-i18next';

function TextInput({ largeText, setLargeText }) {
  const [pdfUrl, setPdfUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const { t } = useTranslation();
  const [value, setValue, ClearButton] = useClearableInput(largeText);

  useEffect(() => {
    setLargeText(value);
  }, [value]);

  useEffect(() => {
    setValue(largeText);
  }, [largeText]);

  const handleFileRead = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      const fileType = file.name.split('.').pop().toLowerCase();

      if (fileType === 'txt') {
        readTextFile(file)
          .then((text) => {
            setLargeText(text);
          })
          .catch((error) => {
            alert(error);
          });
      } else if (fileType === 'docx') {
        readWordFile(file)
          .then((text) => {
            setLargeText(text);
          })
          .catch((error) => {
            alert(error);
          });
      } else if (fileType === 'pdf') {
        readPdfFile(file)
          .then((text) => {
            setLargeText(text);
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        alert(t('unsupportedFileType'));
      }
    }
  };

  const handlePdfUrlLoad = () => {
    readPdfFromUrl(pdfUrl)
      .then((text) => {
        setLargeText(text);
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="form-group position-relative">
      <label style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{t('textLabel')}</label>
      <ClearButton />
      <span
        className="label-characters-count"
        style={{
          position: 'absolute',
          top: '5px',
          right: '10px',
          backgroundColor: 'green',
          color: 'white',
          padding: '2px 6px',
          borderRadius: '5px',
          fontSize: '0.8rem'
        }}
      >
        {largeText.length}
      </span>
      <textarea
        className="form-control"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        rows="5"
        required
        placeholder={t('textAreaPlaceholder')}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
        <div>
          <input
            id="fileInput"
            type="file"
            accept=".txt,.docx,.pdf"
            onChange={handleFileRead}
            style={{ display: 'none' }}
          />
          <button type="button" onClick={() => document.getElementById('fileInput').click()}>
            {t('uploadButton')}
          </button>
          <span style={{ marginLeft: '10px' }}>{fileName}</span>
        </div>

        <div>
          <input
            type="text"
            placeholder={t('pdfUrlPlaceholder')}
            value={pdfUrl}
            onChange={(e) => setPdfUrl(e.target.value)}
          />
          <button type="button" onClick={handlePdfUrlLoad}>
            {t('loadPdfButton')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TextInput;
